<template>
	<section>
    <v-data-table
      :items="regions"
      :headers="tableHeaders"
      disable-pagination
      hide-default-footer
    >
      <template v-slot:top>
				<v-row>
					<v-col cols="12" :sm="11">
					</v-col>
					<v-col cols="12" :sm="1">
						<v-tooltip top>
							Hozzáadás
							<template v-slot:activator="{on, attrs}">
								<v-btn fab color="success" v-on="on" v-bind="attrs" @click="dialogs.addOrChangeRegion.show=true, dialogs.addOrChangeRegion.new=true"><v-icon>fa-plus</v-icon></v-btn>
							</template>
						</v-tooltip>
					</v-col>
				</v-row>
      </template>

      <template v-slot:item.coordinator="{item}">
				<v-btn text :href="$router.resolve({name: 'user', params: {id: item.CoordinatorId}}).href" target="_blank">{{coordinators.find(c => c.id==item.CoordinatorId) ? coordinators.find(c => c.id==item.CoordinatorId).fullname:"-"}}</v-btn>
      </template>
			<template v-slot:item.statistics="{item}">
				{{item.statistics.groupsWithEnoughKidAthletes}} teljes csoport, {{item.statistics.groupsWithoutEnoughKidAthletes}} nem teljes csoport, {{item.statistics.kidAthleteCount}} kölyökatléta (minden csoportot beleértve)
			</template>
			<template v-slot:item.clubs="{item}">
				<v-tooltip top v-for="c in item.KAClubs" :key="c.id">
					<template v-slot:activator="{on, attrs}">
						<v-chip
							:href="$router.resolve({name: 'club', params: {id: c.id}}).href"
							target="_blank"
							color="success"
							v-on="on"
							v-bind="attrs"
						>{{c.MIRClub.name}}</v-chip>
					</template>
					{{c.statistics.groupsWithEnoughKidAthletes}} teljes csoport, {{c.statistics.groupsWithoutEnoughKidAthletes}} nem teljes csoport, {{c.statistics.kidAthleteCount}} kölyökatléta (minden csoportot beleértve)

				</v-tooltip>

			</template>
      <template v-slot:item.actions="{item}">
				<v-tooltip top>
					Módosítás
					<template v-slot:activator="{on, attrs}">
						<v-btn icon color="info" v-bind="attrs" v-on="on" @click="dialogs.addOrChangeRegion.show=true, dialogs.addOrChangeRegion.new=false, dialogs.addOrChangeRegion.region.name=item.name, dialogs.addOrChangeRegion.region.CoordinatorId=item.CoordinatorId, dialogs.addOrChangeRegion.changeId=item.id"><v-icon>fa-pen</v-icon></v-btn>
					</template>
				</v-tooltip>
				<v-tooltip top>
					Törlés
					<template v-slot:activator="{on, attrs}">
						<v-btn icon color="red accent-4" v-bind="attrs" v-on="on" @click="dialogs.deleteRegion.show=true,  dialogs.deleteRegion.regionId=item.id"><v-icon>fa-trash</v-icon></v-btn>
					</template>
				</v-tooltip>
      </template>
    </v-data-table>
		<v-row justify="center">
			<v-dialog
				v-model="dialogs.addOrChangeRegion.show"
				persistent
				max-width="600px"
			>

				<v-card>
					<v-card-title>
						<span class="headline">{{dialogs.addOrChangeRegion.new?"Új régió":"Régió módosítása"}}</span>
					</v-card-title>
					<v-card-text>
						<v-container>
							<v-text-field
								rounded
								outlined
								label="Régió"
								v-model="dialogs.addOrChangeRegion.region.name"
								prepend-inner-icon="fa-map-marked"
							/>
							<v-select
								rounded
								outlined
								label="Koordinátor"
								v-model="dialogs.addOrChangeRegion.region.CoordinatorId"
								:items="dialogs.addOrChangeRegion.new ?coordinators.filter(c => regions.filter(r => r.CoordinatorId==c.id).length==0): coordinators.filter(c => regions.filter(r => r.id==dialogs.addOrChangeRegion.changeId?false:r.CoordinatorId==c.id).length==0)"
								item-text="fullname"
								item-value="id"
								prepend-inner-icon="fa-user-tie"
							/>
						</v-container>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn
							color="blue darken-1"
							text
							@click="dialogs.addOrChangeRegion.show = false, dialogs.addOrChangeRegion.region.name='',dialogs.addOrChangeRegion.region.CoordinatorId=null"
						>
							Mégsem
						</v-btn>
						<span v-if="dialogs.addOrChangeRegion.region.name">
							<v-btn
								color="blue darken-1"
								text
								@click="addRegion()"
								v-if="dialogs.addOrChangeRegion.new"
							>
								Hozzáadás
							</v-btn>
							<v-btn
								color="blue darken-1"
								text
								@click="changeRegion()"
								v-else
							>
								Módosítás
							</v-btn>
						</span>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<v-dialog
				v-model="dialogs.deleteRegion.show"
				persistent
				max-width="600px"
			>

					<v-card>
						<v-card-title>
							<span class="headline">Régió törlése</span>
						</v-card-title>
						<v-card-text>
							<v-container>
								Biztosan törli a régiót?
							</v-container>
						</v-card-text>
						<v-card-actions>
							<v-spacer></v-spacer>
							<v-btn
								color="blue darken-1"
								text
								@click="dialogs.deleteRegion.show = false"
							>
								Mégsem
							</v-btn>
							<v-btn
								color="blue darken-1"
								text
								@click="deleteRegion()"
							>
								Törlés
							</v-btn>
						</v-card-actions>
					</v-card>
			</v-dialog>
		</v-row>

  </section>
</template>

<script>
  const headerProps = {
    align: "center",
    sortable: false,
    filterable: false,
    groupable: false
  }
	export default {
		data: function(){
			return {
        regions: [],
        coordinators: [],
        tableHeaders: [
          {
            text: "RÉGIÓ",
            value: "name",
            ...headerProps
          },
          {
            text: "KOORDINÁTOR",
            value: "coordinator",
            ...headerProps
          },
					{
						text: "Statisztika",
						value: "statistics",
						...headerProps
					},
					{
            text: "Egyesületek",
            value: "clubs",
            ...headerProps
          },
          {
            text: "MŰVELETEK",
            value: "actions",
            ...headerProps
          }
        ],
				dialogs: {
					addOrChangeRegion: {
						show: false,
						new: false,
						changeId: null,
						region: {
							name: '',
							CoordinatorId: null
						}
					},
					deleteRegion: {
						show: false,
						regionId: null
					}
				}
      }
		},
		computed: {
		},
		watch: {

		},
		methods: {
			addRegion: function() {
				this.axios({url: "region/", method: "POST", data: {region: this.dialogs.addOrChangeRegion.region}}).then((response) => {
					if(response.data.success) {
						this.regions.push({...this.dialogs.addOrChangeRegion.region, id: response.data.data.id})
						this.dialogs.addOrChangeRegion.region={name: '', CoordinatorId: null}
						this.dialogs.addOrChangeRegion.show=false;
						this.$store.commit('setSnack','A hozzáadás sikeresen megtörtént.')

					}
				})
			},
			changeRegion: function() {
				this.axios({url: "region/"+this.dialogs.addOrChangeRegion.changeId, method: "PUT", data: {region: this.dialogs.addOrChangeRegion.region}}).then((response) => {
					if(response.data.success) {
						var r = this.regions.find(r => r.id==this.dialogs.addOrChangeRegion.changeId)
						for(var p in this.dialogs.addOrChangeRegion.region) r[p]=this.dialogs.addOrChangeRegion.region[p]
						this.dialogs.addOrChangeRegion.region={name: '', CoordinatorId: null}
						this.dialogs.addOrChangeRegion.show=false;
						this.$store.commit('setSnack','A módosítás sikeresen megtörtént.')
					}
				})
			},
			deleteRegion: function() {
				this.axios({url: "region/"+this.dialogs.deleteRegion.regionId, method: "DELETE"}).then((response) => {
					if(response.data.success) {
						this.regions.splice(this.regions.findIndex(r => r.id==this.dialogs.deleteRegion.regionId),1)
						this.dialogs.deleteRegion.show=false;
						this.$store.commit('setSnack', 'A törlés sikeresen megtörtént.')
					}
				})
			},
      getRegions: function() {
        this.axios({url: "region/", method: "GET"}).then((response) => {
          if(response.data.success) {
            this.regions=response.data.data.regions
            this.coordinators = response.data.data.coordinators
          }
  			})
      },
		},
		mounted(){
      this.getRegions();
		}
	}
</script>
